.customLand {
 height: 100vh;
 padding: 30px;
 position: relative;

 &-intro {
  height: calc(100vh - 60px);
 }

 &-guyMobile {
  display: none;
 }
 &-bubbles {
  opacity: 0;
  transform: scale(0);
  transition-delay: 1s;
 }

 &-bubbles2 {
  opacity: 0;
  transform: scale(0);
  margin-bottom: -60px;
  margin-left: 130px;
  transition-delay: 3s;
 }

 &-title {
  font-style: normal;
  font-weight: normal;
  font-size: 68px;
  line-height: 112%;
  color: #081027;
  max-width: 910px;
  width: 90%;
  div {
    display: inline;
  }
 }

 &-subtitle {
  margin-top: 30px;
  font-size: 16px;
  line-height: 140%;
  color: #878787;
  max-width: 440px;
 }

 &-button {
  position: fixed;
  right: 30px;
  z-index: 1;
  align-items: center;
 }

 &-cover {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 30px;
  bottom: 30px;
 }

 &-buttonApply {
  align-items: center;
  background: $primary-color;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: normal;
  font-size: 24px;
  justify-content: center;
  height: 180px;
  width: 180px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  transition: all 0.25s ease;

  &:hover {
   animation: none;
   transform: rotate(0deg);
  }
 }

 &-form {
  height: calc(100vh - 90px);
  margin-top: 30px;
  max-width: 440px;

  h5 {
   font-weight: normal;
   font-size: 22px;
   line-height: 120%;
   color: #081027;
  }

  button {
   display: block;
   font-family: $font-regular;
   font-size: 16px;
   height: 52px;
   color: #ffffff;
   width: 100%;
  }
  input {
   height: 42px;
  }

  a {
   color: $primary-color;
   transition: all 0.25s ease;
   &:hover {
    color: #000;
   }
  }
 }

 &-formThanks {
  height: calc(100vh - 100px);
  max-width: 440px;

  h5 {
   font-weight: normal;
   font-size: 22px;
   line-height: 120%;
   color: #081027;
  }

  a {
   color: $primary-color;
   transition: all 0.25s ease;
   &:hover {
    color: #000;
   }
  }
 }

 &-footer {
   margin-top: 30px;
  margin-bottom: 30px;

  ul {
   display: flex;
   list-style: none;
  }

  li {
   font-size: 14px;
   line-height: 18px;
   color: #878787;
   margin-bottom: 0;
   margin-right: 30px;

   &:nth-last-child(1) {
    margin-right: 0;
   }
  }

  a {
   font-size: 14px;
   line-height: 18px;
   color: #878787;
   transition: all .25s ease;
   &:hover {
     color: #081027;
   }
  }
 }
}

.eyes {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 100px;
  top: 215px;

  .eye {
    border-radius: 50%;
    height: 25px;
    width: 25px;

    .pupil {
      position: absolute;
      background-color: #081027;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      left: 7px;
      top: 7px;
  
    }
  }
}

.eyesThanks {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 100px;
  top: 33px;

  .eye {
    border-radius: 50%;
    height: 25px;
    width: 25px;

    .pupil {
      position: absolute;
      background-color: #081027;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      left: 7px;
      top: 7px;
  
    }
  }
}

.overlayCustomLand {
 height: 100%;
 width: 0;
 max-width: 1200px !important;
 position: fixed;
 z-index: 999;
 right: 0;
 top: 0;
 background-color: rgba(29, 38, 71, 0);
 overflow: hidden;
 transition: all 1s ease;
}

.overlayCustomLand-content {
 position: relative;
 text-align: left;
 padding: 80px;
 background: $primary-color;
 max-width: 1200px;
 height: 100%;
 margin-left: auto;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 transition: all 0.25s ease;

 &-buy {
  &:hover > .overlay-content {
   background-color: #95983a;
  }
 }

 &-box {
  margin: auto;
  max-width: 500px;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
  opacity: 0;
 }

 ul {
  list-style: none;

  li {
   display: flex;
   font-weight: normal;
   font-size: 16px;
   line-height: 22px;
   color: #ffffff;

   svg {
    margin-right: 15px;
   }
  }
 }

 &__menuMin {
  margin-top: 15%;

  a {
   font-size: 24px !important;
  }

  .enter {
   color: #fff;
   opacity: 0.5;

   &:hover {
    color: #fff;
   }
  }
 }

 h2 {
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 25px;
 }

 &-title {
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 40px !important;
 }

 p {
  margin-bottom: 50px;
 }

 a {
  color: #fff;
  font-family: $font-semibold;
  display: inline-block;
 }

 label {
  font-weight: normal;
  font-size: 13px;
  color: #9aa1b9;
  margin-bottom: 10px;
 }

 &__btnRow {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;

  button {
   font-size: 14px;
   margin-right: 15px;
   height: 36px;
  }
 }

 &__btnRowAlt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
 }

 &__inputRow {
  width: 50%;
  max-width: 275px;

  button {
   font-size: 14px;
   margin-right: 15px;
   height: 36px;
  }
 }
}

.overlayCustomLand .closebtn {
 opacity: 1;
 position: absolute;
 top: 30px;
 right: 30px;
 transition: all 0.25s ease;
 z-index: 1;
 color: #fff;
}

.rotating {
 -webkit-animation: rotating 20s linear infinite;
 -moz-animation: rotating 20s linear infinite;
 -ms-animation: rotating 20s linear infinite;
 -o-animation: rotating 20s linear infinite;
 animation: rotating 20s linear infinite;
}

@-webkit-keyframes rotating {
 from {
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
 }

 to {
  -webkit-transform: rotate(359deg);
  -o-transform: rotate(359deg);
  transform: rotate(359deg);
 }
}

@keyframes rotating {
 from {
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
 }

 to {
  -ms-transform: rotate(359deg);
  -moz-transform: rotate(359deg);
  -webkit-transform: rotate(359deg);
  -o-transform: rotate(359deg);
  transform: rotate(359deg);
 }
}

@media (max-width: 900px) {
  .customLand-guy {
    height: auto;
    width: 250px;
  }
  .eyes {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 60px;
    top: 135px;
  
    .eye {
      border-radius: 50%;
      height: 15px;
      width: 15px;
  
      .pupil {
        position: absolute;
        background-color: #081027;
        border-radius: 50%;
        width: 3px;
        height: 3px;
        left: 5px;
        top: 5px;
    
      }
    }
  }
  .customLand-bubbles {
    height: auto;
    width: 100px;
  }
  .customLand-bubbles2 {
    height: auto;
    width: 50px;
    margin-left: 100px;
    margin-bottom: -40px;
  }
  .customLand-title {
    color: #081027;
    font-size: 58px;
    width: 85%;
   }
}

@media (max-width: 650px) {
 .customLand {
  margin: 30px;
  padding: 0;

  &-button {
   position: initial;
   width: 103px;
   margin: auto;
   margin-bottom: 60px;
   align-items: center;
   justify-content: center;
  }

  &-intro {
   height: auto;
   width: 315px;
   margin: auto;
  }

  &-guy {
   display: none;
  }

  &-bubbles {
   display: none;
  }

  &-bubbles2 {
    display: none;
   }

  &-guyMobile {
   display: block;
   margin: auto;
  }

  &-title {
   font-size: 34px;
   line-height: 110%;
   text-align: center;
   color: #081027;
   width: 100%;
  }

  &-subtitle {
   font-size: 16px;
   line-height: 140%;
   text-align: center;

   color: #878787;
  }

  &-buttonApply {
   position: initial;
   margin: auto;
   margin-top: 30px;
  }

  &-cover {
   position: initial;
   margin-top: 30px;
  }

  &-form {
   height: auto;
   margin-top: 30px;
   margin: auto;
   max-width: 315px;
   margin-bottom: 30px;

   h5 {
    text-align: center;
    font-size: 20px;
    line-height: 150%;
    margin: 60px auto 50px;
    width: 315px;
   }
  }

  &-footer {
    display: block;
    padding-bottom: 30px;
   ul {
    justify-content: center;
    margin-bottom: 60px;
   }
  }
 }

 .overlayCustomLand {
  transition: all .5s ease;

  &-content {
    padding: 40px;
    width: 100%;
  }
 }
 .overlayCustomLand-content-box {
   width: 255px;
 }
 .customLand-formThanks {
   height: 150px;
 }
 .overlayCustomLand .closebtn {
  right: 50%;
  margin-right: -52px;
 }
 .eyes,.eyesThanks {
   display: none;
 }
}