@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,700&display=swap");
@import url("https://rsms.me/inter/inter.css");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.6.0/css/font-awesome.min.css");

@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif;
    }
}

@font-face {
    font-family: "heywowbold";
    src: url("fonts/heywow-bold-webfont.woff2") format("woff2"),
        url("fonts/heywow-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "heywowregular";
    src: url("fonts/heywow-regular-webfont.woff2") format("woff2"),
        url("fonts/heywow-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "heywowmedium";
    src: url("fonts/heywow-semibold-webfont.woff2") format("woff2"),
        url("fonts/heywow-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

$font-regular: "heywowregular",
sans-serif;
$font-semibold: "heywowmedium",
sans-serif;
$font-bold: "heywowbold",
sans-serif;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// html {
//     scroll-behavior: smooth;
// }

:focus {
    outline: none;
}

::-webkit-scrollbar {
    display: none;
}

::-moz-selection { /* Code for Firefox */
    color: #3059C8;
    background: #EAEEFA;
  }
  
  ::selection {
    color: #3059C8;
    background: #EAEEFA;
  }

body {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    font-family: "heywowregular", sans-serif;
    color: #000;
}

$primary-color: #000;
$primary-color: #3059c8;

// TAGS
.tagify {
    $tags-border-color: #e8e8ee !default;
    $tags-hover-border-color: #a6a6a6 !default;
    $tags-focus-border-color: #000 !default;
    $tagMargin: 5px !default;
    $tag-pad: 0.5em 0.8em !default;
    $tag-text-color: #66666a !default;
    $tag-text-color--edit: black !default;
    $tag-bg: rgba(172, 172, 181, 0.1) !default;
    $tag-hover: #eef2fb !default;
    $tag-remove: #081027 !default;
    $tag-remove-btn-bg: none !default;
    $tag-remove-btn-bg--hover: #081027 !default;
    $tag-invalid-color: $tag-remove !default;
    $tag-invalid-bg: rgba($tag-remove, 0.5) !default;
    $tag-inset-shadow-size: 1.5em !default;
    $placeholder-color: $tag-text-color !default;
    $input-color: inherit !default;

    // CSS variables
    --tags-border-color: #{$tags-border-color} !important;
    --tags-hover-border-color: #{$tags-hover-border-color} !important;
    --tags-focus-border-color: #{$tags-focus-border-color} !important;
    --tag-bg: #{$tag-bg} !important;
    --tag-hover: #{$tag-hover} !important;
    --tag-text-color: #{$tag-text-color} !important;
    --tag-text-color--edit: #{$tag-text-color--edit};
    --tag-pad: #{$tag-pad} !important;
    --tag-inset-shadow-size: #{$tag-inset-shadow-size} !important;
    --tag-invalid-color: #{$tag-invalid-color};
    --tag-invalid-bg: #{$tag-invalid-bg};
    --tag-remove-bg: #{rgba($tag-remove, 0.3)} !important;
    --tag-remove-btn-bg: #{$tag-remove-btn-bg};
    --tag-remove-btn-bg--hover: #{$tag-remove-btn-bg--hover} !important;
    --input-color: #{$input-color};
    --tag--min-width: 1ch;
    --tag--max-width: auto;
    --tag-hide-transition: 0.3s;
    --placeholder-color: #{$placeholder-color};
    --loader-size: 0.8em;

    border-radius: 6px;
    min-height: 65px;
}

.tagify__tag>div::before {
    border-radius: 25px !important;
}

.tagify__tag-text {
    overflow: visible !important;
}

.disabledDiv {
    pointer-events: none;
}

.file-drop {
    /* relatively position the dropzone container  */
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .file-drop>.file-drop-target {
   // position: absolute;
   // top: 0;
    //left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
  
    /* horizontally and vertically center all content */
    
  }
  
  .file-drop>.file-drop-target.file-drop-dragging-over-frame {
    /* keeps white background on dropping facturas */
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: none;
    z-index: 50;
    opacity: 1;
  
    /* typography */
    color: black;
  }
  
  .file-drop>.file-drop-target.file-drop-dragging-over-target {
    /* green border when dropping facturas  */
    color: #3059c8;
    box-shadow: 0 0 0 1px #3059c8;
  }

@import "style/base";
@import "style/navigation";
@import "style/home";
@import "style/profile";
@import "style/register";
@import "style/settings";
@import "style/backoffice";
@import "style/customLand";


