.profile-cover {
 padding: 40px 0;
}

.rowProfile {
 margin: auto;
 max-width: 760px;

 &-logo {
  display: flex;
  align-items: center;
 }

 &-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
   color: #000;
  }
 }

 &-thumb {
  height: 60px;
  width: 60px;
  margin-right: 20px;
 }

 &-thumbPro::after {
  content: '';
  background: url('../images/pro_badge.svg');
  background-position: center center;
  background-size: cover;
  height: 23px;
  width: 23px;
  position: absolute;
  margin-left: -40px;
  margin-top: 40px;
 }

 &-actions {
  display: flex;
  justify-content: flex-end;
  width: 50%;

  a {
   background: #ffffff;
   border: 1px solid #eeeeee;
   box-sizing: border-box;
   border-radius: 6px;
   line-height: 0;
   margin-left: 12px;
   padding: 7px;
   height: 36px;
   width: 36px;

   &:hover {
    background: #000;
    border: 1px solid #000;

    path {
     fill: #fff;
    }
   }
  }
 }

 &-about {
  margin-bottom: 20px;
  margin-top: 40px;
 }

 &-title {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
 }
}

.RMM__body {
 background: #fff;
 transition: opacity 0.25s ease !important;
}

.RMM__close-button {
 background: none !important;
}

.RMM__close-button::after,
.RMM__close-button::before {
 background: #081027 !important;
}

.rowLightbox {
  .five {
      margin: auto;
  }
}

.modalCloseButton {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  rect {
    transition: all .3s ease;
  }
  &:hover {
    rect {
      fill-opacity: .3;
    }
  }
}

.modalItem {
 background: #fff;

 &-button {
  align-items: center;
  position: absolute;
  bottom: 40px;
  right: 40px;

  svg {
   margin-left: 10px;
  }
 }

 &-cover {
     background: rgba(0, 0, 0, 0.25);
    height: 100%;
    height: 100vh;
    display: flex;
  img {
   width: 100%;
   width: 80%;
   max-width: 520px;
   margin: auto;
   display: block;
  }
 }

 &-info {
  padding: 40px;

  h5 {
   color: #081027;
   font-family: $font-semibold;
   font-size: 20px;
   line-height: 25px;
   margin-bottom: 10px;
  }

  &__price {
   font-size: 14px;
   line-height: 18px;
   color: #66666a;
   margin-bottom: 40px;
  }

  &__tags {
   font-size: 14px;
   line-height: 18px;
   color: #66666a;
   margin-top: 40px;
  }

  &__button {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 40px;
   width: 100%;

   svg {
    margin-left: 10px;
   }
  }
 }
}

@media (max-width: 600px) {
 .profile-cover {
  padding: 40px;
 }

 .rowProfile-bottom {
  flex-direction: column;
 }

 .rowProfile-actions {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
 }
}