.navbar {
 &-home {
  background: #fff;
  align-items: center;
  display: flex;
  height: 82px;
  justify-content: space-between;
  padding: 0 32px;

  &__search {
   background-position-x: 15px;
   background-position-y: 50%;
   background-repeat: no-repeat;
   margin-bottom: 0;
   padding-left: 40px !important;
   transition: all 0.4s ease;
   width: 100%;
  }

  &__searchExp {
   background-position-x: 15px;
   background-position-y: 50%;
   background-repeat: no-repeat;
   margin-bottom: 0;
   padding-left: 40px !important;
   width: 100%;
  }

  &__searchTags {
   position: absolute;
  }

  &__section {
   width: 30%;
   display: flex;
   align-items: center;
   transition: all 0.4s ease;

   .inputSelect {
    margin-right: 20px;
   }
  }

  &__sectionReduced {
//    width: 0% !important;
   opacity: 0;
   align-items: center;
   transition: all 0.4s ease;

   .inputSelect {
    margin-right: 20px;
   }
  }

  &__section2 {
      width: 40%;
      position: relative;
//    justify-content: flex-end;
   transition: all 0.4s ease;

   input[type="text"] {
    transition: all 0.4s ease;
   }
  }

  &__sectionExp {
    width: 40%;
   margin: auto;
   display: flex;
   align-items: center;
   transition: all 0.4s ease;

   .inputSelect {
    margin-right: 20px;
   }
  }

  &__sectionDropdown {
    border-radius: 6px;
    position: absolute;
    top: 60px;
    width: 100%;
    background: #fff;
    padding: 20px 40px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    &-title {
        font-size: 12px;
        color: #B3B3B4;
        margin-bottom: 20px;
    }
    &-searched {
        ul {
            display: flex;
            list-style: none;
            margin-bottom: 20px;
            justify-content: center;
        }
    }

    &-suggest {
        ul {
            list-style: none;
            p {
              font-size: 14px;
              color: #66666A;
            }
        }
        
    }
    &-new {
        ul {
            list-style: none;
        }
        li {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }
    &-profile {
        align-items: center;
        display: flex;
    }
    &-profileName {
        font-family: $font-semibold;
        font-size: 16px;
        line-height: 20px;
        color: #081027;
    }
  }

  &__sectionDropdownActive {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease;
  }

  &__actions {
   justify-content: flex-end;

   select {
    margin-bottom: 0;
    margin-right: 20px;
    width: 160px;
   }
  }

  &__menuIcon {
   cursor: pointer;
   margin-left: 20px;
  }
 }

 &-homeFocused {
     background: transparent;
  position: relative;
  z-index: 3;
  justify-content: center;
 }

 &-iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  margin-left: 20px;
 }

 &-iconMenu {
  height: auto;
  width: auto;

  div {
   background: #081027;
   border-radius: 5px;
   height: 2px;
   width: 18px;
   display: block;
   margin-bottom: 6px;
   transition: 600ms ease;

   &:first-of-type,
   &:last-of-type {
    transform: translateX(0);
   }

   &:last-of-type {
    margin-bottom: 0;
   }
  }

  &:hover div {
   &:first-of-type {
    transform: translateX(0.4rem);
   }

   &:last-of-type {
    transform: translateX(-0.4rem);
   }
  }
 }

 &-profile {
  height: 30px;
  width: 30px;
 }
}

.okkiLogo {
    position: fixed;
    z-index: 9999;
    left: 30px;
}

.navbarHideShow {
 background: #fff;
 position: fixed;
 width: 100%;
 transition: all 0.5s ease;
 top: 0;
 z-index: 9998;
}

.navbarHideShow2 {
 background: transparent;
 position: fixed;
 width: 100%;
 transition: all 0.5s ease;
 top: 0;
 z-index: 9998;

 .navbar-home {
  background: transparent;
 }
}

.navbarHideShow-hidden {
 top: -82px;
}

.navbarFixed {
 align-items: center;
 display: flex;
 height: 82px;
 justify-content: space-between;
 padding: 0 32px;
 position: absolute;
}

.navbarOverlay {
    background: transparent;
    z-index: 9999;
    transition: all .5s ease;
    overflow: hidden;
}

.navbarOverlay-active {
 position: absolute;
 width: 100%;
 height: 100vh;
 background: rgba(0, 0, 0, 0.5);
 z-index: 0;
 transition: all .5s ease;
}

.footer {
 padding: 80px 0;

 &-title {
  font-family: $font-semibold;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 1.5rem;
 }

 &-subtitle {
  font-family: $font-semibold;
  font-size: 14px;
  margin-bottom: 20px;
 }

 &-menu {
  list-style: none;
  color: #66666a !important;

  a {
   color: #66666a !important;

   &:hover {
    color: $primary-color !important;
   }
  }
 }

 &-column {
  width: 50%;
 }

 &-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  text-align: center;
  width: 100%;
 }

 &-signature {
  color: #999;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 100%;
 }

 &-social {
  a {
   color: #acacb5 !important;
   margin-left: 20px;

   &:hover {
    color: $primary-color !important;
   }
  }
 }
}

.overlay {
 height: 100%;
 width: 0;
 max-width: 580px !important;
 position: fixed;
 z-index: 999;
 right: 0;
 top: 0;
 background-color: rgba(29, 38, 71, 0);
 overflow: hidden;
 transition: all 1s ease;
}

.overlay-content {
 position: relative;
 text-align: left;
 padding: 30% 25%;
 background: $primary-color;
 max-width: 580px;
 height: 100%;
 margin-left: auto;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 transition: all 0.25s ease;

 &-buy {
  &:hover>.overlay-content {
   background-color: #95983a;
  }
 }

 ul {
  list-style: none;
  li {
      margin-bottom: 0;
  }
 }

  &-title {
  
  line-height: 18px;
  a {
    font-size: 14px !important;
    font-family: $font-regular !important;
    &:hover {
        opacity: .5;
    }
  }
 }

 &__menuMin {
  .enter {
   color: #fff;
   opacity: 0.5;

   &:hover {
    color: #fff;
    opacity: 1;
   }
  }
 }

 h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: #fff;
  margin-bottom: 25px;
 }



 p {
  margin-bottom: 50px;
 }

 a {
  color: #fff;
  font-family: $font-semibold;
  display: inline-block;
 }

 label {
  font-weight: normal;
  font-size: 13px;
  color: #9aa1b9;
  margin-bottom: 10px;
 }

 &__btnRow {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;

  button {
   font-size: 14px;
   margin-right: 15px;
   height: 36px;
  }
 }

 &__btnRowAlt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
 }

 &__inputRow {
  width: 50%;
  max-width: 275px;

  button {
   font-size: 14px;
   margin-right: 15px;
   height: 36px;
  }
 }
}

.overlay-contentAlt2 {
 position: relative;
 text-align: left;
 padding: 30% 25%;
 background: #081027;
 max-width: 580px;
 height: 100%;
 margin-left: auto;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 transition: all 0.25s ease;

 &-buy {
  &:hover>.overlay-content {
   background-color: #95983a;
  }
 }

 ul {
  list-style: none;
  li {
      margin-bottom: 0;
  }
 }

  &-title {
  
  line-height: 18px;
  a {
    font-size: 14px !important;
    font-family: $font-regular !important;
    &:hover {
        opacity: .5;
    }
  }
 }

 &__menuMin {
  .enter {
   color: #fff;
   opacity: 0.5;

   &:hover {
    color: #fff;
    opacity: 1;
   }
  }
 }

 h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: #fff;
  margin-bottom: 25px;
 }



 p {
  margin-bottom: 50px;
 }

 a {
  color: #fff;
  font-family: $font-semibold;
  display: inline-block;
  &:hover {
      opacity: .5;
  }
 }

 label {
  font-weight: normal;
  font-size: 13px;
  color: #9aa1b9;
  margin-bottom: 10px;
 }

 &__btnRow {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;

  button {
   font-size: 14px;
   margin-right: 15px;
   height: 36px;
  }
 }

 &__btnRowAlt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
 }

 &__inputRow {
  width: 50%;
  max-width: 275px;

  button {
   font-size: 14px;
   margin-right: 15px;
   height: 36px;
  }
 }
}

.overlayBackoffice {
 height: 100%;
 width: 0;
 max-width: 280px !important;
 position: absolute;
 z-index: 9999;
 right: 0;
 top: 0;
 background-color: rgba(29, 38, 71, 0);
 overflow: hidden;
 transition: all 1s ease;

 &-content {
  background: #081027;
  padding: 40px;
  padding-top: 80px;
 }

 .closebtn {
  top: 5px !important;
  right: 22px !important;
 }
}

.overlay-contentAlt {
 margin-top: 100px;
}

.overlay-contentSml {
 font-size: 25px !important;
}

.overlay a {
 cursor: pointer;
 padding: 8px;
 padding-left: 0;
 text-decoration: none;
 font-size: 26px;
 font-weight: 400;
 color: #f5f5f5;
 display: block;
 transition: 0.3s;
 z-index: 9999;
}

.overlay a:focus {
 color: #fff;
}

.overlay a:hover {
 color: #fff;
}

.overlay .closebtn {
 opacity: 1;
 position: absolute;
 top: 15px;
 right: 40px;
 transition: all 0.25s ease;
 rect {
     fill-opacity: 0;
          transition: all .25s ease;

 }
 &:hover {
    rect {
     fill-opacity: 0.1;
 }
 }
}

@media (max-width: 768px) {
 .footer-column {
  margin-top: 1.5rem;
  width: 50%;
 }
}