.settings-headerModal {
  margin-top: 0 !important;
  padding: 0 !important;
}
.settings-header {
 margin-top: 82px;
 padding: 50px 0 0;

 &__title {
  font-family: $font-semibold;
  font-size: 20px;
  line-height: 120%;
  color: #000000;
 }

 &__breadcrumb {
  display: flex;
  flex-direction: column;

  .button {
   align-items: center;
   margin-left: auto;

   svg {
    margin-left: 10px;
   }
  }
 }

 &__breadcrumbAlt {
  display: flex;
  margin-bottom: 40px;

  .button {
   align-items: center;
   margin-left: auto;

   svg {
    margin-left: 10px;
   }
  }
 }

 &__breadcrumbCatalog {
  display: flex;
  margin-bottom: 20px;

  .button {
   align-items: center;
   margin-left: auto;

   svg {
    margin-left: 10px;
   }
  }
 }

 &__breadcrumbArrow {
  margin: 0 14px;
 }
}

.settings-headerAlt {
  padding-top: 132px;
}

.resultsNumber {
 color: #3059c8;
 font-size: 12px;
 margin-left: 10px;
}

.settingsDivider {
 margin: 20px auto 40px 0;
 width: 220px;
}

.settings-form {
  .modal-text {
    text-align: center;
  }
  &__modal {
    overflow: scroll;
    max-height: 570px;
  }
 &__menu {
  list-style: none;
  margin-bottom: 50px;
  li {
    margin-bottom: 0;
  }

  a {
    border-radius: 6px;
   color: #999999;
   display: block;
   padding: 10px;
   width: 220px;
  }

  .active {
  background: rgba(238, 242, 251, 0.5);
   color: $primary-color;
  }
 }

 &__productPic {
  background: rgba(232, 232, 238, 0.1);
  border: 1px dashed #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  // height: 100%;
  min-height: 400px;
  max-width: 400px;
  margin-left: auto;
  position: relative;

  .itemTagAlt {
   margin-left: 0;
  }
 }

 &__productPicPreview {
  background: rgba(232, 232, 238, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  // height: 100%;
  min-height: 400px;
  max-width: 400px;
  margin-left: auto;
  position: relative;


 }

 &__productPicDelete {
   cursor: pointer;
   position: absolute;
   top: 20px;
   right: 20px;
 }

 &__productPicNote {
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #999999;
  margin-top: 20px;
 }

 &__row {
  display: flex;
  margin-bottom: 20px;
 }

 &__rowDouble {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
 }

 &__rowLast {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
  width: 65.3333333333%;
  margin-left: 30%;
  position: absolute;
  bottom: 0;
  right: 80px;
  &-modal {
    background: #fff;
  }

  &-modalWelcome {
    background: #fff;
    bottom: 100px;
    justify-content: center;
    margin-left: 0;
    width: 100%;
    right: 0;
    .button {
      align-items: center;
      svg {
        margin-left: 5px;
      }
    }
  }
 }

 &__sidebar {
   position: fixed;
 }

 &__container {
   margin-left: 30%;
 }

 &__column {
   position: relative;
  width: 50%;
 }

 .columnRow {
  display: flex;
  align-items: center;
 }

 &__input {
  width: 100%;
 }

 &__thumb {
  height: 60px;
  width: 60px;
 }

 .okkiurlInput {
  padding-left: 100px;

  &-placeholder {
   color: #999;
   position: absolute;
   padding: 6px 10px;
   line-height: 26px;
  }
 }

 .facebookurlInput {
  padding-left: 108px;

  &-placeholder {
   color: #999;
   position: absolute;
   padding: 6px 10px;
   line-height: 26px;
  }
 }

 .instagramurlInput {
  padding-left: 111px;

  &-placeholder {
   color: #999;
   position: absolute;
   padding: 6px 10px;
   line-height: 26px;
  }
 }

 .phoneInput {
  padding-left: 40px;

  &-placeholder {
   color: #999;
   position: absolute;
   padding: 6px 10px;
   line-height: 26px;
  }
 }

 .mapsInput {
  padding-left: 100px;

  &-placeholder {
   color: #999;
   position: absolute;
   padding: 6px 10px;
   line-height: 26px;
  }
 }

 .btnDelete {
  background: rgba(246, 60, 68, 0.08);
  color: #F63C44;
  display: inline-block;
  &:hover {
    background: #ff000b;
    color: #fff;
  }
 }
}

.slick-slider {
  max-height: 400px;
}

.proButton {
 position: absolute;
 width: 360px;
 text-align: center;
 z-index: 9;
 margin-top: 100px;
}

.newProduct {
 &__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 360px;
 }

 &__row2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 360px;
 }

 &__divider {
  margin: 20px auto 20px 0;
  max-width: 360px;
 }

 &__proFeature {
  opacity: 0.25;
 }

 &__column {
  width: 100%;
 }

 &__column2 {
  width: 75%;

  &__column3 {
   width: 25%;

   label {
    margin-bottom: 0 !important;
   }
  }
 }

 &__input {
  width: 100%;
 }

 &__rowDouble {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 360px;
 }

 &__proTitle {
  font-family: $font-semibold;
  font-size: 12px;
  line-height: 15px;
  color: #081027;
  margin-bottom: 20px;
 }
}

.newProduct-header {
 align-items: center;
 background: #3059c8;
 color: #fff;
 display: flex;
 font-family: $font-semibold;
 font-size: 16px;
 line-height: 20px;
 justify-content: center;
 height: 82px;
 margin-bottom: 100px;
}

.newProduct-rowLast {
 display: flex;
 justify-content: space-between;

 .button {
  align-items: center;
  display: flex;
 }
}

.newProduct-divider {
 border-color: #e8e8ee;
 margin: 30px 0 !important;
}

.fileDrop-container {
  min-height: 400px;
    max-width: 400px;
    margin-left: auto;
    height: 100%;
}

.dropdownMenu {
 display: flex;
 flex-direction: column;
}

.dropdownItem {
 cursor: pointer;
 font-size: 14px;
 padding: 12px 14px;

 &:hover {
  background: rgba(238, 242, 251, 0.5);
 }
}

.modal {
 display: none;
 position: fixed;
 z-index: 9999;
 left: 0;
 top: 0;
 width: 100%;
 height: 100%;
 overflow: auto;
 background-color: rgb(0, 0, 0);
 background-color: rgba(0, 0, 0, 0.4);

 .button {
  align-items: center;
  display: flex;

  svg {
   margin-left: 10px;
  }
 }
}

.modalCookies {
  background-color: transparent !important;
}

.modal-title {
 font-family: $font-semibold;
 font-size: 30px;
 line-height: 38px;
 color: #081027;
 margin: 20px 0;
}

.modal-subtitle {
 font-family: $font-semibold;
 font-size: 18px;
 line-height: 38px;
 color: #081027;
 margin-bottom: 20px;
}

.modal-label {
 font-weight: 500;
 font-size: 12px;
 color: #111111;
 margin-bottom: 14px;
}

.modal-text {
 padding: 80px;
 padding-top: 0;
}

.modal-textCookies {
  align-items: center;
  display: flex;
  padding: 11px;
 svg {
   cursor: pointer;
   margin-right: 11px;
 }
 a {
   color: $primary-color;
 }
 a:hover {
  color: $primary-color;
  opacity: .7;
}
}

.modal-divider {
 margin-bottom: 32px;
}

.modal-cover {
 text-align: center;
 width: 100%;

 img {
  margin-top: -40px;
 }
}

.modal-row {
 display: flex;
 justify-content: space-between;
 margin-bottom: 32px;

 &:nth-last-child(1) {
  margin-bottom: 0;
 }
}

.modal-columnBig {
 width: 66%;
}

.modal-columnSmall {
 width: 33%;
}

.modal-columnMid {
 width: 33%;
}

.modal-content {
 background-color: #fefefe;
 margin: 10% auto;
 border-radius: 8px;
 width: 80%;
 max-width: 500px;
}

.modal-cookies {
 background-color: #fefefe;
 border-radius: 8px;
 width: 80%;
 max-width: 380px;
 position: absolute;
 bottom: 40px;
 left: 50%;
 transform: translateX(-50%);
}

.modal-content2 {
 background-color: #fefefe;
 margin: 10% auto;
 border-radius: 8px;
 width: 80%;
}

.modal-text2 {
 padding: 40px;
}

.close {
 color: #aaa;
 float: right;
 font-size: 28px;
 font-weight: bold;
 margin-top: 10px;
 margin-right: 15px;

 path {
  transition: all 0.25s ease;
 }
}

.close:hover,
.close:focus {
 color: black;
 text-decoration: none;
 cursor: pointer;

 path {
  transition: all 0.25s ease;
  fill: #000;
 }
}
