.home {
 &-cover {
  padding: 182px 0 100px 0;

  &__title {
   font-family: $font-semibold;
   font-size: 3rem;
   line-height: 61px;
   color: #081027;
  }

  &__subtitle {
   color: #66666a;
   font-size: 1.25rem;
   margin-top: 40px;
  }

  &__img {
   width: 100%;
  }

  &__profiles {
   display: flex;
   margin-top: 40px;
  }

  &__profilesResult {
   display: flex;
   margin: 40px 0;
  }
 }

 &-gallery {
  z-index: 9997;

  &__margin {
   margin-top: 82px;
  }

  &__marginPlus {
   margin-top: 132px;
  }

  &__header {
   align-items: center;
   display: flex;
   justify-content: center;
   margin-bottom: 50px;

   select {
    width: 160px;
    margin-bottom: 0;
   }
  }

  &__filtersProfile {
   justify-content: center !important;
  }

  &__filters {
   border-top: 1px solid #e8e8ee;
   border-bottom: 1px solid #e8e8ee;
   padding: 15px 0;
   margin-bottom: 40px;
   display: flex;
   align-items: center;
   justify-content: space-between;

   &-corner {
    display: block;
    width: 28px;
   }

   &-list {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
   }

   li {
    color: #66666a;
    font-family: $font-semibold;
    margin-bottom: 0;
    padding: 0 8px;
   }
  }

  &__headerResults {
   align-items: center;
   display: flex;
   justify-content: space-between;
   padding: 40px 0;

   select {
    width: 160px;
    margin-bottom: 0;
   }
  }

  &__headerTags {
   align-items: center;
   display: flex;
   justify-content: space-between;
   line-break: anywhere;
   margin-bottom: 45px;

   a {
    margin-bottom: 10px !important;
    display: inline-block;
   }
  }

  &__headerSelects {
   display: flex;
   align-items: center;
   justify-content: flex-end;

   .inputSelect {
    margin-left: 20px;
   }
  }
 }
}

.profile-card {
 align-items: center;
 cursor: pointer;
 display: flex;
 flex-direction: column;
 width: 98px;

 &__icon {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  border: none;
  margin-bottom: 10px;
 }
}

.profile-card__large {
 align-items: center;
 display: flex;
 width: 100%;
 justify-content: space-between;
 margin-bottom: 40px;

 &__icon {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
 }

 &-block {
  align-items: center;
  display: flex;
 }

 .profile-card__icon {
  margin-right: 20px;
 }
}

.toggle-btn {
 cursor: pointer;
 width: 32px;
 height: 32px;
 background: #fff;
 border-radius: 50px;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: all 0.25s ease;

 &:hover {
  background: #000;

  circle {
   fill: #fff;
  }
 }
}

.homeGrid {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
 grid-column-gap: 40px;

 &-item {
  width: 260px;
  margin: auto;
  margin-bottom: 50px;
  position: relative;

  &__dropdown {
   display: flex;
   justify-content: space-between;
  }

  &__cover {
   opacity: 1;
   display: block;
   width: 100%;
   height: auto;
   transition: 0.5s ease;
   backface-visibility: hidden;
  }

  &__coverOverlay {
   background: rgba(0, 0, 0, 0.15);
   transition: 0.5s ease;
   opacity: 0;
   position: absolute;
   top: 0;
   text-align: center;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
   padding: 20px;
  }

  &__top {
   position: relative;
  }

  &__info {
   display: flex;
   margin-top: 20px;

   p:nth-child(2) {
    color: #999;
   }
  }

  &__icon {
   max-width: 30px;
   margin-right: 16px;
  }
 }

 &-item__top:hover {
  .homeGrid-item__cover {
   box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .homeGrid-item__coverOverlay {
   opacity: 1;
  }
 }
}

.homeGrid2 {
 display: grid;
 grid-template-columns: 1fr 1fr;
 grid-column-gap: 40px;

 &-item {
  max-width: 560px;
  margin: auto;
  margin-bottom: 50px;
  position: relative;

  img {
   width: 100%;
  }

  &__cover {
   opacity: 1;
   display: block;
   width: 100%;
   height: auto;
   transition: 0.5s ease;
   backface-visibility: hidden;
  }

  &__coverOverlay {
   background: rgba(0, 0, 0, 0.15);
   transition: 0.5s ease;
   opacity: 0;
   position: absolute;
   top: 0;
   text-align: center;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
   padding: 20px;
  }

  &__top {
   position: relative;
  }

  &__info {
   display: flex;
   justify-content: space-between;
   margin-top: 20px;

   p:nth-child(2) {
    color: #999;
   }
  }

  &__icon {
   max-width: 30px;
   margin-right: 16px;
  }
 }

 &-item:hover {
  .homeGrid-item__cover {
   box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .homeGrid-item__coverOverlay {
   opacity: 1;
  }
 }
}

.about-container {
 padding: 7.5rem 0;
 margin-top: 82px;

 h5.accordion {
  color: #1d2647;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin-bottom: 10px;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
 }

 /* Add a background color to the accordion if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
 h5.accordion.active,
 h5.accordion:hover {
  color: $primary-color;
 }

 .panel {
  color: #999999;
  padding: 0 40px 40px;
  text-align: left;
 }

 /* Style the element that is used for the panel class */

 div.panel {
  padding: 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  opacity: 0;
  margin-bottom: 10px;
 }

 div.panel.show {
  opacity: 1;
  max-height: 500px;
  /* Whatever you like, as long as its more than the height of the content (on all screen sizes) */
 }

 &__cover {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
   margin-right: 40px;
   margin-top: 100px;
  }
 }

 &__infoBox {
  border: 1px solid #e8e8ee;
  border-radius: 6px;
  margin-left: 40px;
  max-width: 360px;
  width: 100%;
 }

 &__infoItemDiv {
  border-bottom: 1px solid #e8e8ee;

  &:nth-last-child(1) {
   border-bottom: none;
  }
 }

 &__infoItem {
  padding: 40px;
  padding-bottom: 0;

  &:nth-last-child(1) {
   padding-bottom: 40px;
  }

  h5 {
   color: #081027;
   font-family: $font-semibold;
   font-size: 1rem;
   margin-bottom: 20px;
  }

  p {
   font-size: 14px;
   line-height: 18px;
   color: #66666a;
  }
 }
}

.searchError-container {
 height: 400px;
 display: flex;
 align-items: center;
 justify-content: center;
}

.searchError-message {
 color: #999;
 font-family: $font-semibold;
 margin-top: 6rem;
 margin-bottom: 2rem;
 font-size: 18px;
 text-align: center;

 span {
  color: #000;
 }
}

.page404-message {
 color: #000;
 margin-top: 5rem;
 font-size: 18px;
 text-align: left;
 max-width: 480px;
 font-family: $font-semibold;
}

.page404-link {
 color: #999;
 margin-top: 1.5rem;
 font-size: 14px;
 text-align: left;
 max-width: 480px;
}

.rowLightbox {
 display: flex;
 align-items: center;
}

@media (max-width: 1280px) {
 .homeGrid {
  grid-template-columns: 1fr 1fr 1fr;
 }
}

@media (max-width: 1140px) {
 .navbar-home__section {
  width: 48%;
 }
}

@media (max-width: 980px) {
 .navbar-home__actions {
  display: none;
 }

 .homeGrid {
  grid-template-columns: 1fr 1fr;
 }

 .homeGrid2 {
  grid-template-columns: 1fr;
 }

 .navbar-home__section {
  width: 24%;
 }

 .navbar-home__section2 {
  width: 74%;
 }
}

@media (max-width: 768px) {
 .home-cover__profilesResult {
  overflow-x: scroll;

  &::-webkit-scrollbar {
   display: none;
  }
 }

 .home-gallery__headerResults {
  flex-direction: column;

  .inputSelect:nth-child(1) {
   margin-left: 0;
  }
 }

 .navbar-home__searchTags {
  display: none;
 }

 .profile-card {
  margin-right: 20px;
 }

 .home-cover {
  padding: 100px 0 50px 0;

  &__img {
   margin-bottom: 50px;
  }

  &__profiles {
   justify-content: center;
  }
 }

 .rowCenter {
  display: flex;
  flex-direction: column-reverse;
 }

 .home-gallery__header {
  flex-direction: column;

  &-div {
   text-align: center;
   margin-bottom: 40px;

   .inputSelect {
    float: none;
    margin: auto;
   }
  }

  select {
   float: none;
  }
 }

 .about-container {
  padding: 3rem 0;
  margin-top: 82px;

  &__cover {
   display: flex;
   align-items: center;
   justify-content: center;

   img {
    margin-right: 0;
    margin-top: 0;
   }
  }

  &__infoBox {
   border: 1px solid #e8e8ee;
   border-radius: 6px;
   margin: 0 auto;
   margin-top: 5rem;
   max-width: 360px;
   width: 100%;
  }
 }

 .RMM__container--is-active {
  margin-top: 82px;
 }

 .rowLightbox {
  flex-direction: column;
 }
}

@media (max-width: 600px) {
 .homeGrid-item {
  width: 150px;

  img {
   width: 100%;
  }
 }

 .homeGrid-item__icon {
 }
}
