.sidebar {
 background: #081027;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 padding: 40px;
 max-width: 260px;
 height: 100vh;
 position: fixed;
    width: 20%;

 &-menu {
  list-style: none;

  li {
   margin-bottom: 40px;

   a {
    color: #fff;
    opacity: 0.5;
   }

   &:nth-last-child(1) {
    margin-bottom: 0;
   }
  }

  .active {
   opacity: 1;
  }
 }
}

.sidebarResponsive {
 background: #081027;
 display: none;
 justify-content: space-between;
 padding: 20px;
 width: 100%;
 height: 62px;

 &-menu {
  list-style: none;

  li {
   margin-bottom: 40px;

   a {
    color: #fff;
    opacity: 0.5;
   }

   &:nth-last-child(1) {
    margin-bottom: 0;
   }
  }

  .active {
   opacity: 1;
  }
 }

 .navbar-iconWrapper {
  .navbar-iconMenu div {
   background: #fff;
  }
 }

 .navbar-iconWrapper {
  height: 22px;
  width: 22px;
 }
}

.backofficeContainer {
 width: calc(100% - 20%);
  margin-left: auto;

 &__optionTotal {
  align-items: center;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
 }

 &-header {
  padding: 40px;
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;

  &__about {
   align-items: baseline;
   display: flex;
  }

  &__title {
   font-weight: bold;
   font-size: 18px;
   line-height: 23px;
   color: #000000;
  }

  &__titleAlt {
   font-size: 14px;
   line-height: 23px;
   color: $primary-color;
   margin-left: 10px;
  }
 }

 &-headerMin {
  padding: 0 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 82px;

  select {
   margin-right: 20px;
  }

  &-tab {
   color: #081027;
   margin-right: 20px;
  }

  .active {
   color: $primary-color;
  }
 }
}

.backoffice {
 display: flex;
}

.backofficeTable {
 padding: 0 40px;

 hr {
   border-color: #E8E8EE;
  margin-bottom: 20px;
  max-width: none;
 }

 th {
  border-color: #E8E8EE;
  color: #66666a;
  padding-bottom: 20px;
  padding-top: 0;
  font-family: $font-semibold !important;
 }

 td {
  border-color: #E8E8EE;
  color: #081027;
 }

 table {
  width: 100%;
 }

 .table-link {
  color: $primary-color;
 }

 .dotYes {
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: center;
  color: $primary-color;
 }

 .dotNo {
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #acacb5;
 }
}

.approvedBtns {
 display: flex;
 justify-content: space-around;

 a {
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  font-family: "Inter", sans-serif;

  &:hover {
   background: $primary-color;
   color: #fff;

   path {
    stroke: #fff;
   }
  }
 }
}

.table-link {
 text-decoration: underline;
}

.ReactModal__Overlay--after-open {
 background-color: rgba(0, 0, 0, 0.2) !important;
}

.modalTitle {
 font-weight: bold;
 font-size: 18px;
 line-height: 23px;
 color: #000000;
 margin-bottom: 20px;
}

.modalInput {
 margin-bottom: 20px;

 select {
  width: 100%;
 }

 &-subcategory {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
 }

 &-subcategoryName {
  display: flex;
  svg {
   margin-right: 10px;
  }
 }
}
.MuiPaper {
  &-root {
      box-shadow: none !important;
      white-space: nowrap;
      // position: relative !important;
      margin-top: -67px !important;
      margin-bottom: 60px !important;
      // background: transparent !important;
  }
}

.MuiTableCell-root {
    font-family: $font-regular !important;
}

.modalRow {
 margin-bottom: 0;
}

@media (max-width: 768px) {
 .backoffice {
  flex-direction: column;
 }

 .backofficeContainer {
  &-header {
   align-items: flex-start;
   flex-direction: column;
   padding: 0;
   margin: 40px;
  }
 }

 .sidebar {
  display: none;
 }

 .sidebarResponsive {
  display: flex;
 }
}
