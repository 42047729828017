.planContainer {
 display: flex;
 height: 100vh;

 &-cover {
  background: #081027;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 50%;
 }

 &-form {
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__containerLarge {
    height: 100vh;
    overflow: auto;
    padding-bottom: 250px;
    padding-top: 100px;
  }

  &__container {
   margin: auto;
   max-width: 360px;
   width: 100%;
   z-index: 1;


   .phoneInput {
    padding-left: 40px;

    &-placeholder {
     color: #999;
     position: absolute;
     padding: 6px 10px;
     line-height: 26px;
    }
   }
  }

  &__thanks {
   text-align: center;

   h1 {
    font-family: $font-semibold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #081027;
   }

   p {
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: #66666a;
   }
  }

  &__input {
   width: 100%;
  }

  &__title {
   font-family: $font-bold;
   font-size: 18px;
   line-height: 23px;
   margin-bottom: 10px;
   font-weight: bold;
  }

  &__subtitle {
   color: #555555;
   font-family: $font-regular;
   font-size: 0.875rem;
   line-height: 1.25rem;
   margin-bottom: 1.25rem;
  }

  &__subtitleAlt {
   font-family: $font-regular;
   font-size: 0.875rem;
   line-height: 1.25rem;
   margin-bottom: 1.25rem;
   color: #66666A;
  }

  &__labelWrap {
   font-weight: normal;
   margin-bottom: 0;
   cursor: pointer;
   padding: 1.25rem;
  }

  &__option {
   background: #ffffff;
   border: 1px solid #eeeeee;
   border-radius: 4px;
   cursor: pointer;
   margin-bottom: 1.25rem;
   display: flex;
   flex-direction: column;

   .planContainer-form__optionList {
    display: none;
   }
  }

  &__optionRow {
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-family: $font-regular;
  }

  &__optionAction {
   align-items: center;
   display: flex;

   p {
    color: #555555;
    font-family: $font-regular;
   }

   input[type="radio"] {
    margin-bottom: 0;
    margin-left: 1.25rem;
   }
  }

  &__optionList {
   list-style: none;
   margin-top: 1.25rem;

   li {
    color: #555555;
    margin-bottom: 0;
   }

   div {
    white-space: pre-wrap;
   }
  }

  &__optionExtra {
   align-items: center;
   display: flex;
   justify-content: space-between;
   margin: 1.25rem 0;

   p {
    color: #111111;
    font-family: $font-regular;
   }

   div {
    align-items: center;
    display: flex;
    color: $primary-color;

    p {
     font-family: $font-regular;
    }
   }

   .inputSelect {
    margin-bottom: 0;
    margin-left: 1.25rem;
    width: 68px;
   }
  }

  &__optionTotal {
   align-items: center;
   display: flex;
   justify-content: space-between;
   padding: 40px;

   position: absolute;
   bottom: 0;

   width: 50%;

   z-index: 1;

   background: #fff;

   p {
    font-family: $font-regular;
   }

   span {
    color: $primary-color;
    font-weight: 500;
   }

   .total {
    color: $primary-color;
    display: flex;

    strong {
     margin-left: 0.5rem;
    }
   }

   .totalNumber {
    font-weight: 500;
   }

   .label {
    color: #111111;
    font-weight: 500;
   }
  }

  &__optionTotalShadow {
    box-shadow: 0 0 4px rgba(0, 0, 0, .15);
   }

  &__optionInfo {
   align-items: center;
   display: flex;
   justify-content: space-between;
   margin-top: 1.25rem;

   p {
    font-family: $font-regular;
   }

   a {
    line-height: 38px;
   }

   .label {
    color: #111111;
    font-weight: 500;
   }

   .total {
    color: #555;
   }

   .totalNumber {
    font-weight: 500;
   }
  }
 }
}

.registerForm {
 margin-top: -10px;

 &-row {
  display: flex;
  justify-content: space-between;

  &:nth-last-child(1) {
   margin-top: 20px;
  }
 }
}

.registerInput {
 margin-bottom: 20px;
 width: 100%;
}

.loginContainer {
 align-items: center;
 background: #081027;
 display: flex;
 justify-content: center;
 height: 100vh;
 width: 100%;

 &-form {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__link {
   color: #fff;

   &:hover {
    color: #a8a8a8;
   }
  }
 }

 &-card {
  background: #fff;
  border-radius: 6px;
  margin: 40px 0;
  padding: 40px;
  width: 440px;

  &__title {
   font-weight: bold;
   font-size: 18px;
   line-height: 23px;
   margin-bottom: 40px;
  }

  &__row {
   align-items: center;
   display: flex;
   justify-content: space-between;

   label {
    margin-bottom: 0;
   }
  }

  &__checkbox {
   margin-right: 10px;
  }

  &__input {
   margin-bottom: 20px;

   &:nth-last-child(2) {
    margin-bottom: 40px;
   }

   input {
    width: 100%;
   }
  }
 }
}

.radioContainer {
 display: block;
 position: relative;
 padding-left: 35px;
 margin-bottom: 12px;
 cursor: pointer;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 font-family: $font-regular;
 font-size: 14px;
 line-height: 18px;

 color: #081027;

}

.radioContainer input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
 height: 0;
 width: 0;
}

.checkmark {
 position: absolute;
 top: 0;
 left: 0;
 height: 22px;
 width: 22px;
 background-color: #eee;
 border: 1px solid transparent;
 border-radius: 50%;
 transition: all 0.25s ease;
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 18px;
 
  color: #081027;
 
 }
 
 .checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
 }

.checkmarkSquare {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  transition: all 0.25s ease;
 }

.radioContainer:hover input~.checkmark {
 background-color: $primary-color;
}

.radioContainer input:checked~.checkmark {
 background-color: $primary-color;
}

.checkboxContainer:hover input~.checkmarkSquare {
  background-color: $primary-color;
 }
 
 .checkboxContainer input:checked~.checkmarkSquare {
  background-color: $primary-color;
 }

.checkmark:after {
 content: " ";
 background: url("/assets/check.svg") no-repeat;
 background-position: center;
 position: absolute;
 display: none;
}

.radioContainer input:checked~.checkmark:after {
 display: inline-block;
}

.radioContainer .checkmark:after {
 top: 5px;
 left: 5px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 // background: white;
}

.checkmarkSquare:after {
  content: " ";
  background: url("/assets/check.svg") no-repeat;
  background-position: center;
  position: absolute;
  display: none;
 }
 
 .checkboxContainer input:checked~.checkmarkSquare:after {
  display: inline-block;
 }
 
 .checkboxContainer .checkmarkSquare:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  // background: white;
 }

.signupContainer {
 overflow-x: hidden;
}

.pass-wrapper {
 position: relative;
 display: flex;
 margin-bottom: 14px;

 input {
  width: 100%;
 }

 svg {
  position: absolute;
  top: 23%;
  right: 5%;
 }

 svg:hover {
  path {
   stroke: #081027;
   transition: all 0.25s ease;
  }

  cursor: pointer;
 }
}

@media (max-width: 600px) {
 .planContainer-cover {
  display: none;
 }

 .planContainer-form {
  width: 100%;

  &__optionTotal {
   width: 100%;
  }
 }
}

@media screen and ( min-height: 900px ) {
  .planContainer-form__containerLarge {
    height: auto;
  }
}